.form {
  display: flex;
  margin: 1 auto;
  justify-content: center;
}

.form form {
  flex: 1;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.header {
  color: var(--accent-color);
  margin: 0;
}

.wrapper {
  width: 100%;
  margin: 0.5rem 0 1rem;
  font-size: 14px;
}

.wrapper label {
  display: block;
  margin-bottom: 0.5rem;
}

label + label {
  font-size: 12px;
}

.wrapper input,
.wrapper textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border: 0;
  font-size: 14px;
  border-radius: 0.25rem;
  padding: 1rem;
  background: none;
  color: var(--main-text-color);
  background: var(--light-bg-color);
}

.wrapper input:focus,
.wrapper textarea:focus {
  background: var(--dark-bg-color);
  outline: 0;
}

.wrapper input {
}

.wrapper textarea {
  min-height: 3rem;
}

.checkboxLabel {
  align-items: baseline;
  font-size: 12px;
}

.wrapper input[type="checkbox"] {
  margin-right: 0.5rem;
}

.error {
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-radius: 0.25rem;
  background: var(--main-text-color);
  color: var(--accent-color);
}
