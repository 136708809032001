.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}

.logo {
  flex: 1;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  margin-top: 2rem;
}

.logo .whip {
  width: 2rem;
  margin: 1rem;
}

.navigation {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 2rem;
}

@media (orientation: portrait) {
  .header {
    flex-direction: column;
  }
  .navigation {
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 1rem;
  }
}

.navigation a ~ a {
  margin-left: 1rem;
}

.header a,
.header a:visited {
  color: var(--main-text-color);
  text-decoration: none;
  cursor: pointer;
}

.logo a,
.navigation a {
  text-decoration: none;
  border-bottom: 2px solid transparent;
}

.logo a:hover,
.navigation a:hover {
  border-bottom: 2px solid;
}
