.cover {
  flex: 1;
  margin: 0.25rem;
}

.cover img {
  width: 100%;
  height: auto;
  border-radius: 0.125rem;
}

.heading {
  text-align: center;
  letter-spacing: 0.15rem;
}
