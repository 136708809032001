.wrapper {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.name {
  margin: 0;
}
