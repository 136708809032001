.event {
  flex: 1 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 60rem;
  align-self: center;
}

.controls {
  display: flex;
  justify-content: space-between;
}

.title {
  color: var(--accent-color);
}

.when {
  text-transform: capitalize;
}

.responses {
  display: flex;
  flex-direction: column;
}

.response {
  margin-bottom: 1rem;
  display: flex;
}

.response .name {
  color: var(--accent-color);
  margin: 0;
  flex: 1 30%;
}

.comment {
  margin: 0;
  flex: 1 70%;
}

.summary {
  display: flex;
  flex-wrap: wrap;
}

.counts {
  display: flex;
  flex-direction: column;
  flex: 1 1 300px;
}

.item {
  margin-bottom: 1rem;
  display: flex;
}

.item .title {
  color: var(--accent-color);
  margin: 0;
  /*flex: 1 97%;*/
}

.item .count {
  margin: 0;
  /*flex: 1 3%;*/
}
