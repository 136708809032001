@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,700");

:root {
  --light-bg-color: #ec5f99;
  --dark-bg-color: #ee2a7b;
  --main-bg-color: #e83d84;

  --main-text-color: #1e1d21;
  --accent-color: #f9f9fa;
  --dark-accent-color: #ee2a7b;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--main-bg-color);
  color: var(--main-text-color);

  line-height: 1.6rem;
  font-family: "rubik";
}

.main {
  flex: 1;
  width: 90%;
  margin: 0 auto;
  padding: 1rem;
  max-width: 80rem;
  display: flex;
  flex-direction: column;
}

.home {
  flex: 1;
  display: flex;
  justify-content: center;
}

.home .whip {
  width: 80%;
  max-width: 80vmin;
}

.main a {
  text-decoration: none;
  border-bottom: 2px solid transparent;
}

.main a:visited {
  color: var(--accent-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
}

.page h1 {
  font-weight: 700;
  letter-spacing: 0.15rem;
  text-align: center;
}

.page h3 {
  font-weight: 500;
  letter-spacing: 0.05em;
}

.page h4 {
  font-weight: 400;
}

.page h5 {
  font-weight: 500;
}

.page p {
  line-height: 1.6;
  margin: 1rem 0;
}
